
export enum IndentifierTypes {
    PHONE = "PHONE",
    EMAIL = "EMAIL"
}

export enum MessageType {
    Info = "info",
    Error = "error"
}

export const RespCodes = {
    SUCCESS: 2000,
    PASSWORD_NOT_SET: 4020
};

export const toRecommendedRedirection = (actionCode: string) => {

    switch (actionCode) {
        case 'NAVIGATE_TO_OTP_CHALLENGE':{
            return '/auth/otp'
        }

        case 'NAVIGATE_TO_HOME':
            return '/'
    }

    return '/'
};