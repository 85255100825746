import React, {useState, useEffect} from 'react';

import 'css/orders.css'
import {postRequest} from "services/rest/RestService";
import {notifyHttpError} from "services/notification/notifications";
import {Button, Col, Flex, Form, Input, Row} from "antd";
import {useCartContext} from "state/contexts/CartContext";
import {useMerchantContext} from "state/contexts/MerchantContext";
import {isEmpty} from "utils/helpers";
import {CheckoutResponse, OrderQuotation} from "interfaces/OrderInterfaces";

import {
    CheckCircleOutlined,
    ShoppingCartOutlined, TruckOutlined,
    UserOutlined
} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {useAuthContext} from "state/contexts/AuthContext";
import {isVisible} from "utils/GoodCSSUtils";
import {BiShoppingBag} from "react-icons/bi";


const CheckoutPage = () => {

    const {merchant} = useMerchantContext()
    const [isLoading, setIsLoading] = useState(false);
    const [isLoginFormVisible, setLoginFormVisible] = useState(false);
    const [orderQuotation, setOrderQuotation] = useState<OrderQuotation | undefined>(undefined);
    const [checkoutResponse, setCheckoutResponse] = useState<CheckoutResponse>();

    const [orderForm] = Form.useForm()
    const navigate = useNavigate()

    // Contexts
    const {cartProducts, clearCart} = useCartContext()
    const {customer, isAuthenticated} = useAuthContext()

    //Fetch products
    useEffect(() => {
        fetchQuotation();
        toHomeIfEmpty();
    }, [merchant]);

    //Fetch Packages
    const fetchQuotation = () => {
        if (isEmpty(merchant.id)) {
            return;
        }
        setIsLoading(true)
        const payload = {
            "branchId": merchant.branch?.id,
            "businessId": merchant.id,
            "cartProducts": formatCartProducts(),
            "deliveryInformation": {},
            "customer": {
                "email": "",
                "phoneNumber": ""
            },
            "paymentInformation": {
                "paymentMethodId": 4
            }
        }

        const uri = "/api/v1/market/public/checkout/order/quotation";
        console.log(`fetching quotation order... ${uri} ${JSON.stringify(payload)}`)
        postRequest(uri, payload)
            .then((response) => {
                setIsLoading(false)
                setOrderQuotation(response.data.respBody.businessOrder)
                console.log(JSON.stringify(response.data.respBody.businessOrder));
            }).catch((errorObj) => {
            setIsLoading(false)
            notifyHttpError('Sorry, request failed. Try again later', errorObj)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const submitOrder = (customerInformation: any) => {
        if (isEmpty(merchant.id)) {
            console.log(`merchant.id is empty`)
            return;
        }

        const payload = {
            "branchId": merchant.branch?.id,
            "businessId": merchant.id,
            "cartProducts": formatCartProducts(),
            "deliveryInformation": {
                country: "Tanzania",
                region: customerInformation.city,
                street: customerInformation.street
            },
            "customer": {
                fullName: customerInformation.fullName,
                phoneNumber: customerInformation.phoneNumber,
                email: customerInformation.email,
            },
            "paymentInformation": {
                "paymentMethodId": 4
            }
        }

        cacheCustomerInformation(customerInformation)
        console.log(`submitting new order... ${JSON.stringify(payload)}`)
        setIsLoading(true)
        postRequest("/api/v1/market/public/checkout/order/submit", payload)
            .then((response) => {
                setIsLoading(false)
                setCheckoutResponse(response.data)
                postCheckout((response.data as CheckoutResponse));
            }).catch((errorObj) => {
            setIsLoading(false)
            notifyHttpError('Sorry, request failed. Try again later', errorObj)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const formatCartProducts = () => {
        return cartProducts.map(localCartProduct => {
            return {
                productVariantId: localCartProduct.productVariant?.id ?? '',
                quantity: localCartProduct.quantity
            }
        });
    }

    const summaryItem = (label: string, value: number, suffix: string) => {
        return <div style={{borderBottom: '1px dashed #a1a1a1', padding: '0px 0px', margin: '0px 0px 8px 0px'}}>
            <Flex gap="middle">
                <p style={{width: '50%'}}>{label}</p>
                <p style={{width: '50%', textAlign: 'end'}}>
                    {(value).toLocaleString()} {suffix}
                </p>
            </Flex>
        </div>
    }

    const totalItem = (label: string, value: number, suffix: string) => {
        return <div style={{ padding: '0px 0px', margin: '0px 0px 8px 0px'}}>
            <Flex gap="middle">
                <p style={{width: '50%'}}>{label}</p>
                <p style={{width: '50%', textAlign: 'end', fontSize: '18px', fontWeight: 'bold'}}>
                    {(value).toLocaleString()} {suffix}
                </p>
            </Flex>
        </div>
    }

    const toHomeIfEmpty = () => {
        if (cartProducts.length == 0) {
            navigate(`/`)
        }
    }

    const postCheckout = (checkoutResponse: CheckoutResponse) => {
        console.log('post checkout', JSON.stringify(checkoutResponse))
        clearCart();
        navigateToOrderStatus(checkoutResponse.respBody?.businessOrder?.orderNumber);
    }

    const navigateToOrderStatus = (orderNumber: string) => {
        navigate(`/orders/${orderNumber}`)
    }

    const cacheCustomerInformation = (customerInformation: any) => {
        localStorage.setItem('phone',customerInformation.phoneNumber);
        localStorage.setItem('email',customerInformation.email);
        console.log(`localStorage ${localStorage.getItem('phone')}`)
    }

    return <div className="container" style={{
        maxWidth: '900px',
        padding: '32px 24px',
        borderRadius: '8px',
        marginTop: "64px",
        marginBottom: "64px",
        backgroundColor: "#ffffff"
    }}>

        <div>
            <Row gutter={16}>

                <Col span={24} lg={12} md={12} sm={24}>
                    <div className="order-summary-holder" >
                        <h3 > <BiShoppingBag/> Order Summary </h3>

                        {/*Sub Total*/}
                        {summaryItem("Sub Total", orderQuotation?.itemsPricesBeforeDiscount ?? 0, 'TZS')}

                        {/*Tax*/}
                        {summaryItem("Tax", orderQuotation?.vat ?? 0, 'TZS')}

                        {/*Discount*/}
                        {summaryItem("Discount", orderQuotation?.totalDiscountsIssued ?? 0, 'TZS')}

                        {/*Due*/}
                        {totalItem("Total Amount Due", orderQuotation?.amountDue ?? 0, 'TZS')}
                    </div>
                </Col>


                {/* User Details Form */}
                <Col span={24} lg={12} md={12} sm={24}>
                    <Form
                        form={orderForm}
                        layout="horizontal"
                        labelCol={{span: 10}}
                        onFinish={submitOrder}
                        initialValues={{
                            'fullName': 'John',
                            'phoneNumber': '255687850882',
                            'email': 'rjkilonzo@gmail.com',
                            'city': 'Dar',
                            'street': 'Dar'
                        }}
                    >

                        <div className={isVisible(!isAuthenticated)} style={{
                            border: '1px solid #d1d1d1',
                            padding: '24px 24px',
                            borderRadius: '12px',
                            backgroundColor: '#fffdf3'
                        }}>

                            <h3 style={{margin: '0 0 12px 0'}}><UserOutlined/> Your Information</h3>

                            {/* Full Name */}
                            <Form.Item required name="fullName" label="Full Name">
                                <Input placeholder=""/>
                            </Form.Item>

                            {/* Phone Number */}
                            <Form.Item required name="phoneNumber" label="Phone Number"
                                       tooltip="We will use this to update you about order status and delivery">
                                <Input placeholder=""/>
                            </Form.Item>

                            {/* Email */}
                            <Form.Item name="email" label="Email"
                                       tooltip="We will use this to send you receipts and other important attachements">
                                <Input placeholder=""/>
                            </Form.Item>

                        </div>


                        <div style={{
                            border: '1px solid #d1d1d1',
                            padding: '24px 24px',
                            borderRadius: '12px',
                            marginTop: '24px',
                            backgroundColor: '#fffdf3'
                        }}>

                            <h3 style={{marginTop: '16px', margin: '0 0 0 0'}}>
                                <TruckOutlined style={{fontSize: '20px'}}/> Delivery Information
                            </h3>
                            <p style={{margin: '0 0 12px 0'}}>Where would you like to receive your products?</p>

                            <Form.Item name="city" label="City or Region" required>
                                <Input placeholder=""/>
                            </Form.Item>

                            <Form.Item name="street" label="Street" required>
                                <Input placeholder=""/>
                            </Form.Item>
                        </div>

                        <Form.Item>
                            <Button size="large"
                                    loading={isLoading} block
                                    onClick={() => {
                                        orderForm.submit()
                                    }}
                                    style={{marginTop: '24px',
                                        color:'white',
                                        backgroundColor:'green'}}>
                                Submit Order
                                <CheckCircleOutlined />
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div>

    </div>

}

export default CheckoutPage

