import React, {createContext, useContext, useEffect, useState} from 'react';
import {Customer} from "interfaces/CustomerInterfaces";
import {getStoredUser} from "state/auth/authStore";
import {LocalUser} from "interfaces/system/AuthInterfaces";
import {isNotEmpty} from "utils/helpers";

interface AuthContextProps {
    customer: Customer | undefined;
    isAuthenticated: boolean | false;
    setCustomer: (customer: Customer) => void;
    removeCustomer: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider = ({children}: any) => {

    const [customerInstance, updateCustomer] = useState<Customer>();
    const [isAuthenticated, setAuthenticated] = useState<boolean>(false);

    useEffect(() => {
        const localUser: LocalUser = getStoredUser()
        updateCustomer({firstName: localUser.name})
        setAuthenticated(isNotEmpty(localUser?.token))
    }, []);

    const setCustomer = (customer: Customer) => {
        updateCustomer(customer)
        setAuthenticated(true)
        localStorage.setItem("customer", JSON.stringify(customer))
    }

    const removeCustomer = () => {
        updateCustomer(undefined)
        setAuthenticated(false)
    }

    return (
        <AuthContext.Provider
            value={{customer: customerInstance, isAuthenticated, setCustomer, removeCustomer}}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthContext = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuthContext must be used within a AuthContext');
    }
    return context;
};

export default AuthContext;
