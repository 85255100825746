import React, {useState} from 'react';
import {postRequest} from "../../../services/rest/RestService";
import {notifyError, notifyHttpError, notifySuccess} from "../../../services/notification/notifications";
import {Button, Form, Input} from "antd";

import {KeyOutlined, LockOutlined} from "@ant-design/icons";
import {useNavigate, useSearchParams} from "react-router-dom";
import MessageCard, {buildInfoMessage, PromptMessage} from "../../components/MessageCard";
import RecommendedActionsDialog from "../../components/RecommendedActionsDialog";
import {ServerRecommendedAction} from "../../../interfaces/system/ServerResponseInterfaces";

interface CustomQueryParams {
    email: string | '';
    phone: string | '';
    redirectionUrl: string | '',
    msg: string | ''
}

const PasswordCreationPage = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [promptMessage, setPromptMessage] = useState<PromptMessage>();
    const [recommendedActions, setRecommendedActions] = useState<ServerRecommendedAction[]>([]);

    const navigate = useNavigate();
    const [passwordForm] = Form.useForm();

    const setPassword = (dataObject: any) => {

        if (dataObject.password !== dataObject.passwordConfirm ?? '') {
            notifyError("Invalid Data", "The two passwords should be the same")
            return;
        }

        const payload: any = {
            'password': dataObject.password
        };
        const uri: string = `/api/v1/market/customer/profile/password/update`;
        console.log(`set password... ${uri} ${JSON.stringify(payload)}`);
        setIsLoading(true)

        postRequest(uri, payload).then((response) => {
            setIsLoading(false)
            console.log(response.data)
            notifySuccess('Password Updated')
            navigate("/")
        }).catch((errorObj) => {
            console.error(errorObj)
            setIsLoading(false)
            notifyHttpError('Login Failed', errorObj)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const onValidationFailed = (values: any) => {
        console.log(`values ${JSON.stringify(values)}`);
    };

    return <div className="container" style={{
        maxWidth: '520px',
        padding: '32px',
        marginTop: "64px",
        borderRadius: '12px',
        border: '1px solid #d1d1d1',
        marginBottom: "64px",
        backgroundColor: "#ffffff"
    }}>

        <RecommendedActionsDialog actions={recommendedActions}
                                  title="Login Request Failed"/>

        <div>

            <h3 style={{margin: '0 0 16px 0'}}>
                <LockOutlined size={64}/> Create a Password
            </h3>
            <MessageCard promptMessage={buildInfoMessage('Create a password that you can remember')} />

            <Form
                form={passwordForm}
                name="passwordForm"
                onFinish={setPassword}
                layout="vertical"
                onFinishFailed={onValidationFailed}
                requiredMark={false}
                colon={false}
                autoComplete="off">

                <Form.Item
                    name="password"
                    label="Password"
                    rules={[{required: true}]}>
                    <Input.Password size='large' prefix={<KeyOutlined/>}/>
                </Form.Item>

                <Form.Item
                    name="passwordConfirm"
                    label="Confirm Password"
                    rules={[{required: true}]}>
                    <Input.Password size='large' prefix={<KeyOutlined/>}/>
                </Form.Item>

                <Form.Item>
                    <Button size="large" style={{backgroundColor: "#00509d"}}
                            loading={isLoading} type="primary"
                            htmlType="submit" block>
                        Save
                    </Button>
                </Form.Item>

            </Form>

        </div>

    </div>

}

export default PasswordCreationPage

