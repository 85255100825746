import React, {useState, useEffect} from 'react';
import {getRequest} from "services/rest/RestService";
import {Button, Col, Flex, Row,Tag} from "antd";

import {useNavigate} from "react-router-dom";

import {useCartContext} from "state/contexts/CartContext";
import {useMerchantContext} from "state/contexts/MerchantContext";
import {notifyHttpError} from "services/notification/notifications";
import {Customer} from "interfaces/CustomerInterfaces";
import GoodListItemWidget from "ui/components/GoodListItemWidget";
import GoodLoadingWidget from "ui/components/GoodLoadingWidget";
import {KeyOutlined, MailOutlined, MobileOutlined, UserOutlined} from "@ant-design/icons";
import {isVisible} from "utils/GoodCSSUtils";

const ProfilePage = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [customer, setOrder] = useState<Customer | undefined>(undefined);

    const navigate = useNavigate();
    const {merchant, setMerchant} = useMerchantContext()

    const { addCartProduct} = useCartContext()

    useEffect(() => {
        fetchProfileDetails();
    }, [merchant]);

    const fetchProfileDetails = () => {
        const uri = `/api/v1/market/customer/profile`;
        console.log(uri)
        setIsLoading(true)
        getRequest(uri).then((response) => {
            setIsLoading(false)
            setOrder(response.data.respBody);
        }).catch((errorObj) => {
            console.error(errorObj)
            setIsLoading(false)
            notifyHttpError('Operation Failed', errorObj)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    return <div className="container" style={{
        padding: '16px',
        maxWidth: '1080px',
        marginTop: "36px",
        marginBottom: "64px",
        backgroundColor: "#ffffff"
    }}>

        <h3>Profile
            <GoodLoadingWidget isLoading={isLoading}/>
        </h3>

        <Row gutter={8}>

            <Col span={24} lg={12} md={12}>

                <div style={{
                    border: '1px solid #d1d1d1',
                    marginRight: '24px',
                    padding: '16px 24px'
                }}>

                    <div style={{padding: '0px 0px', margin: '0px 0px 8px 0px'}}>
                        <Flex gap="middle">
                            <UserOutlined/>
                            <p style={{width: '50%', margin: '0'}}>Name</p>
                            <p style={{width: '50%', margin: '0', textAlign: 'end'}}>
                                <Tag style={{
                                    fontSize: '14px',
                                    padding: '2px 16px'
                                }}>{customer?.firstName} {customer?.lastName}</Tag>
                            </p>
                        </Flex>
                    </div>

                    <div style={{width: '100%', borderBottom: '1px dashed #818181', margin: '16px 0px'}}></div>

                    {/*Sub Total*/}
                    <GoodListItemWidget icon={<MobileOutlined/>} label="Phone" value={customer?.phoneNumber ?? ''}/>
                    <GoodListItemWidget icon={<MailOutlined/>} label="Email" value={customer?.email ?? ''}/>

                    <div style={{width: '100%', borderBottom: '1px dashed #818181', margin: '16px 0px'}}></div>

                    <GoodListItemWidget label="Joined" value={customer?.createdDate ?? ''}/>

                    <div style={{width: '100%', borderBottom: '1px dashed #818181', margin: '16px 0px'}}></div>

                    <Button className={isVisible(!(customer?.authChallenges?.canChallengePassword??false))} style={{ border: '1px solid #00a6fb', color:'#00a6fb'}} href="/auth/password/set">
                        <KeyOutlined/> Set a Password
                    </Button>

                </div>

            </Col>

            <Col span={24} lg={12} md={12}>
                <div style={{
                    border: '1px solid #d1d1d1',
                    marginBottom: '24px',
                    padding: '8px 8px'}}>

                    <h3> Delivery Addresses </h3>

                </div>
            </Col>

        </Row>


    </div>

}

export default ProfilePage

