import React, {useEffect, useState} from 'react';
import {postRequest} from "../../../services/rest/RestService";
import {notifyHttpError} from "../../../services/notification/notifications";
import {Button, Form, Image, Input, Radio, Space} from "antd";

import {KeyOutlined, MailOutlined, MobileOutlined, UserOutlined,} from "@ant-design/icons";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setName, setToken} from "../../../state/auth/authStore";
import {isNotEmpty} from "../../../utils/helpers";
import dummyImage from "../../../assets/images/icons/dummy.jpg";
import {MessageType, RespCodes} from "../../../constants/constants";
import MessageCard, {PromptMessage} from "../../components/MessageCard";
import RecommendedActionsDialog from "../../components/RecommendedActionsDialog";
import {ServerRecommendedAction} from "../../../interfaces/system/ServerResponseInterfaces";
import {useAuthContext} from "../../../state/contexts/AuthContext";
import {Customer} from "../../../interfaces/CustomerInterfaces";

interface CustomQueryParams {
    email: string | '';
    phone: string | '';
    redirectionUrl: string | '',
    msg: string | ''
}



const LoginPage = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [identifierType, setIdentifierType] = useState('PHONE');
    const [queryParams, setQueryParams] = useState<CustomQueryParams>();
    const [promptMessage, setPromptMessage] = useState<PromptMessage>();
    const [recommendedActions, setRecommendedActions] = useState<ServerRecommendedAction[]>([]);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [searchParams] = useSearchParams();
    const query = searchParams.get('query'); // Access specific query param

    const [loginForm] = Form.useForm();
    const { setCustomer} = useAuthContext()


    useEffect(() => {
        parseUrlParams();
    }, []);

    const attemptLogin = (payloadData: any) => {
        const payload: any = {
            'identifier': payloadData.identifier,
            'identifierType': payloadData.identifierType,
            'password': payloadData.password
        };
        const uri: string = `/api/v1/market/auth/login/password`;
        console.log(`attempting login... ${uri} ${JSON.stringify(payload)}`);

        setIsLoading(true)
        postRequest(uri, payload).then((response) => {
            setIsLoading(false)
            console.log(response.data)
            parseLoginResponse(response.data);

        }).catch((errorObj) => {
            console.error(errorObj)
            setIsLoading(false)
            notifyHttpError('Login Failed', errorObj)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const parseLoginResponse = (loginResponse: any) => {

        const code: number = loginResponse.respCode;
        if (code !== RespCodes.SUCCESS) {
            setPromptMessage({msg: loginResponse.message, type: MessageType.Error});
            setRecommendedActions(loginResponse.actions)
            return ;
        }

        const localCustomer = {firstName: loginResponse.respBody.firstName, identifier: identifierType } as Customer;
        onSuccessfulLogin(loginResponse.respBody.token,localCustomer)
    }

    const onValidationFailed = (values: any) => {
        console.log(`values ${JSON.stringify(values)}`);
    };

    const onIdentifierTypeChange = (item: any) => {
        if (item.target.value == 'EMAIL') {
            loginForm.setFieldValue('identifier', queryParams?.email)
            setIdentifierType('EMAIL')
        } else {
            loginForm.setFieldValue('identifier', queryParams?.phone)
            setIdentifierType('PHONE')
        }
    };

    const onSuccessfulLogin = (newToken: string,customer: Customer) => {
        console.log(`login successful... ${JSON.stringify(customer)}`)
        dispatch(setToken(newToken));
        dispatch(setName(customer?.firstName));
        setCustomer(customer)
        redirect();
    }

    const redirect = () => {
        if (isNotEmpty(queryParams?.redirectionUrl)) {
            navigate(queryParams?.redirectionUrl ?? '/');
        } else {
            navigate('/');
        }
    }

    const parseUrlParams = () => {
        console.log(`Parsing url params ${query}`)
        if (isNotEmpty(query)) {
            const paramsJsonString = window.atob(query ?? '')
            const queryParams = JSON.parse(paramsJsonString)
            setQueryParams(queryParams)
            setPromptMessage({type: MessageType.Info, msg: queryParams.msg})
            loginForm.setFieldsValue({
                'identifier': queryParams?.phone,
                'identifierType': 'PHONE'
            })
        } else {
            console.log(`Empty query params`)
            setQueryParams({phone: '', email: '', redirectionUrl: '', msg: ''})
        }
    }


    return <div className="container" style={{
        maxWidth: '520px',
        padding: '32px',
        marginTop: "64px",
        borderRadius: '12px',
        border: '1px solid #d1d1d1',
        marginBottom: "64px",
        backgroundColor: "#ffffff"
    }}>

        <RecommendedActionsDialog actions={recommendedActions}
                                  title="Login Request Failed"/>

        <div>
            <Space>
                <Image src={dummyImage} width={64}></Image>
                <h3>
                    <UserOutlined/> Login
                </h3>
            </Space>

            {/**---------------*
             /** Info Message
             ** --------------- */}
            <MessageCard  promptMessage={promptMessage} >

            </MessageCard>

            <Form
                form={loginForm}
                name="loginForm"
                initialValues={{
                    identifierType: 'PHONE'
                }}
                onFinish={attemptLogin}
                layout="vertical"
                onFinishFailed={onValidationFailed}
                requiredMark={false}
                colon={false}
                autoComplete="off">


                <Form.Item name="identifierType" style={{marginTop: '16px'}}>
                    <Radio.Group onChange={onIdentifierTypeChange}>
                        <Radio value="PHONE">Phone Number</Radio>
                        <Radio value="EMAIL">Email</Radio>
                    </Radio.Group>
                </Form.Item>


                <Form.Item
                    name="identifier"
                    label={identifierType == 'PHONE' ? 'Phone Number' : 'Email'}
                    rules={[{required: true}]}>
                    <Input size='large' prefix={identifierType == 'PHONE' ? <MobileOutlined/> : <MailOutlined/>}/>
                </Form.Item>

                <Form.Item
                    name="password"
                    label="Password"
                    rules={[{required: true}]}>
                    <Input.Password size='large' prefix={<KeyOutlined/>}/>
                </Form.Item>

                <Form.Item>
                    <Button size="large" style={{backgroundColor: "#00509d"}}
                            loading={isLoading} type="primary"
                            htmlType="submit" block>
                        Login
                    </Button>
                </Form.Item>


                <Form.Item>
                    <Button href="/auth/otp" size="large"
                            loading={isLoading} type="link"
                            htmlType="submit" block>
                        Forgot your password? Get a one time pin
                    </Button>
                </Form.Item>

            </Form>

        </div>

    </div>

}

export default LoginPage

