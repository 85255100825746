import React, {useState, useEffect} from 'react';
import {getRequest} from "services/rest/RestService";
import {notifyHttpError} from "services/notification/notifications";
import {Col, Pagination, Row} from "antd";

import {GoodPagination, Product} from "interfaces/ProductInterfaces";
import {useMerchantContext} from "state/contexts/MerchantContext";
import {isEmpty} from "utils/helpers";
import ProductWidget from "./components/ProductWidget";
import {useSearchContext} from "state/contexts/SearchContext";
import 'css/products.css';
import GoodLoadingWidget from "ui/components/GoodLoadingWidget";
import CategoriesWidget from "ui/pages/products/CategoriesWidget";

const ProductsListPage = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [productsList, setProducts] = useState<Product[]>([]);
    const [paginationInfo, setPaginationInfo] = useState<GoodPagination>({currentPageNo: 0, pageSize: 25});
    const {merchant} = useMerchantContext();
    const {queryTerm,topLevelCategory} = useSearchContext();

    useEffect(() => {
        console.log("merchant: ", JSON.stringify(merchant ?? {}))
        setPaginationInfo({...paginationInfo, currentPageNo: 0})
        fetchProducts();
    }, [merchant, queryTerm,topLevelCategory]);

    //Fetch products
    const fetchProducts = () => {

        if (isEmpty(merchant?.id)) {
            console.log("merchant id is empty...")
            return;
        }

        console.log("Fetching products...")
        setIsLoading(true)

        const uri = `/api/v1/market/public/products?businessId=${merchant?.id}&query=${queryTerm}&categoryId=${topLevelCategory?.id??''}&pageNo=${paginationInfo?.currentPageNo}&pageSize=${paginationInfo?.pageSize}`;

        getRequest(uri)
            .then((response) => {
                setIsLoading(false)
                setProducts(response.data.items)
                setPaginationInfo(response.data)
            }).catch((errorObj) => {
            setIsLoading(false)
            notifyHttpError('Operation Failed', errorObj)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const onPageChange = (pageNumber: number, pageSize: number) => {
        paginationInfo.currentPageNo = (pageNumber - 1);
        fetchProducts();
    }

    return <div className="container products-holder">

        <GoodLoadingWidget isLoading={isLoading}/>

        <CategoriesWidget/>

        <Row>
            {productsList.map((product: Product) => {
                return <Col key={product.id}
                            span={12}
                            lg={6}
                            md={8}>
                    <ProductWidget product={product}/>
                </Col>
            })}
        </Row>

        <div className="pagination-container">
            <Pagination
                total={paginationInfo?.totalElements}
                pageSize={paginationInfo?.pageSize}
                current={(paginationInfo?.currentPageNo ?? 0) + 1}
                onChange={onPageChange}
                showSizeChanger={false}
                showQuickJumper
            />
        </div>

    </div>;

}

export default ProductsListPage

