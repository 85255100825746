import {Checkbox, Image,  Space } from 'antd';
import React from 'react';
import productPlaceholder from "assets/images/icons/product.png";
import {CartProduct} from "interfaces/ProductInterfaces";
import {isEmpty} from "utils/helpers";
import 'css/products.css'

interface WidgetProps {
    onRemove: (cartProduct: CartProduct)=>void,
    cartProduct: CartProduct,
    isSelected: boolean
}

const CartProductWidget = ({cartProduct,isSelected,onRemove}: WidgetProps) => {

    const onItemSelected = (event: any) => {
        const hasSelected = event.target.checked;
        console.log("onItemSelected", hasSelected);
        if(!hasSelected){
            onRemove(cartProduct)
        }
    }

    const onProductVariantClick = () => {
        //onSelectionChange(cartProduct,true)
    }

    return <>
        <div
            style={{
                cursor:'pointer',
                border: isSelected ?  '1px solid #a1a1a1' : '1px solid #e1e1e1',
                margin: '16px 8px',
                padding: '8px 8px'
            }}>

            <Space>
                <Checkbox checked={isSelected} onChange={onItemSelected} style={{}}/>

                <Image
                    onClick={onProductVariantClick}
                    style={{
                        border: '1px solid #a1a1a1'
                    }}
                    preview={false}
                    width="96px"
                    src={isEmpty(cartProduct.productVariant.thumbnailUrl) ? productPlaceholder: cartProduct.productVariant.thumbnailUrl}
                    alt={cartProduct.productVariant.variantName}></Image>

                <div>
                    <p style={{
                        padding: '0px 0px',
                        margin: '2px 0px 4px 0px',
                        fontWeight: 'bold',
                        color: isSelected ? '#3a86ff' : '#2a2a2a'
                    }}>
                        TZS {(cartProduct.productVariant.price).toLocaleString()}
                    </p>

                    <p className="cart-product-info">
                        {cartProduct.productVariant.variantName}
                    </p>
                    <p className="cart-product-info">
                       Quantity {cartProduct.quantity}
                    </p>
                </div>
            </Space>


        </div>
    </>;
}

export default CartProductWidget

