import {Checkbox, Image} from 'antd';
import React from 'react';
import {ProductVariant} from "../../../../interfaces/ProductInterfaces";
import productPlaceholder from "../../../../assets/images/icons/product.png";
import {isEmpty} from "../../../../utils/helpers";
import dummyLogo from '../../../../assets/images/icons/dummy.jpg';


interface ProductProps {
    onSelectionChange: any,
    variant: ProductVariant,
    isSelected: boolean
}


const ProductVariantWidget = ({variant,isSelected,onSelectionChange}: ProductProps) => {

    const onItemSelected = (event: any) => {
        const hasSelected = event.target.checked;
        onSelectionChange(variant,event.target.checked)
    }

    const onProductVariantClick = () => {
        onSelectionChange(variant,true)
    }

    return <>
        <div className="product-variant-holder">

            <Checkbox checked={isSelected} onChange={onItemSelected} style={{}}> {variant.variantName} </Checkbox>
            <p style={{
                padding: '0px 0px',
                margin: '2px 0px 4px 0px',
                fontWeight: 'bold' ,
                color: isSelected ? '#3a86ff' : '#2a2a2a'}}>
                TZS {(variant.price).toLocaleString()}
            </p>
            <Image
                onClick={onProductVariantClick}
                style={{
                    border: '1px solid #f1f1f1'
                }}
                preview={false}
                width="100%"
                src={isEmpty(variant.thumbnailUrl) ? productPlaceholder: variant.thumbnailUrl}
                alt={variant.variantName}></Image>
        </div>
    </>;
}

export default ProductVariantWidget

