import { Image, Space} from 'antd';
import React from 'react';
import {OrderProduct} from "../../../../interfaces/OrderInterfaces";
import {isEmpty} from "../../../../utils/helpers";
import productPlaceholder from "assets/images/icons/product.png"

interface WidgetProps {
    orderProduct: OrderProduct,
}

const OrderProductWidget = ({orderProduct}: WidgetProps) => {

    return <>
        <div
            style={{
                cursor:'pointer',
                border: '1px solid #a1a1a1',
                margin: '16px 8px',
                padding: '8px 8px'
            }}>

            <Space>

                <Image
                    style={{
                        border: '1px solid #a1a1a1'
                    }}
                    preview={false}
                    width="96px"
                    src={isEmpty(productPlaceholder) ? productPlaceholder: orderProduct.thumbnailUrl}
                    alt={orderProduct.thumbnailUrl}></Image>

                <div>
                    <p style={{
                        padding: '0px 0px',
                        margin: '2px 0px 4px 0px',
                        fontWeight: 'bold',
                        color:'#2a2a2a'
                    }}>
                        TZS {(orderProduct.sellingPrice).toLocaleString()}
                    </p>
                    <p>
                        {orderProduct.productName}
                    </p>
                </div>
            </Space>


        </div>
    </>;
}

export default OrderProductWidget

