import React, {useState, useEffect} from 'react';
import {getRequest} from "../../../../services/rest/RestService";
import {notifyHttpError} from "../../../../services/notification/notifications";
import { Col, Flex, Row, Tag} from "antd";

import {useParams} from "react-router-dom";
import {isEmpty} from "../../../../utils/helpers";
import {useCartContext} from "state/contexts/CartContext";
import {useMerchantContext} from "state/contexts/MerchantContext";
import {Order, OrderProduct} from "../../../../interfaces/OrderInterfaces";
import OrderProductWidget from "./OrderProductWidget";

const OrderDetailsPage = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [orderDetails, setOrder] = useState<Order | undefined>(undefined);
    const [orderProducts, setOrderProducts] = useState<OrderProduct[]>([]);
    const {orderId} = useParams();
    const {merchant, setMerchant} = useMerchantContext()

    useEffect(() => {
        fetchOrderDetails();
    }, [merchant]);


    const fetchOrderDetails = () => {

        if (isEmpty(merchant.id)) {
            console.log('merchant id is empty...')
            return;
        }
        const uri = `/api/v1/market/customer/orders/details?id=${orderId}`;
        console.log(uri)
        setIsLoading(true)
        getRequest(uri).then((response) => {
            setIsLoading(false)
            setOrder(response.data.respBody.businessOrder);
            setOrderProducts(response.data.respBody.orderProducts)
        }).catch((errorObj) => {
            console.error(errorObj)
            setIsLoading(false)
            notifyHttpError('Operation Failed', errorObj)
        }).finally(() => {
            setIsLoading(false)
        })
    }


    const summaryItem = (label: string, value: number, suffix: string) => {
        return <div style={{padding: '0px 0px', margin: '0px 0px 8px 0px'}}>
            <Flex gap="middle">
                <p style={{width: '50%', margin: '0'}}>{label}</p>
                <p style={{width: '50%',margin: '0',textAlign: 'end'}}>
                    {(value).toLocaleString()} {suffix}
                </p>
            </Flex>
        </div>
    }

    const summaryItemText = (label: string, value: string, suffix: string) => {
        return <div style={{padding: '0px 0px', margin: '0px 0px 8px 0px'}}>
            <Flex gap="middle">
                <p style={{width: '50%', margin: '0'}}>{label}</p>
                <p style={{width: '50%',margin: '0',textAlign: 'end'}}>
                    {(value)} {suffix}
                </p>
            </Flex>
        </div>
    }

    const totalItem = (label: string, value: number, suffix: string) => {
        return <div style={{borderTop: '1px dashed #a1a1a1', padding: '0px 0px', margin: '16px 0px 8px 0px'}}>
            <Flex gap="middle">
                <p style={{width: '50%'}}>{label}</p>
                <p style={{width: '50%', textAlign: 'end', fontSize: '14px', fontWeight: 'bold'}}>
                    {(value).toLocaleString()} {suffix}
                </p>
            </Flex>
        </div>
    }


    return <div className="container" style={{
        padding: '16px',
        maxWidth: '1080px',
        marginTop: "36px",
        marginBottom: "64px",
        backgroundColor: "#ffffff"
    }}>

        <Row gutter={8}>

            <Col span={24} lg={12} md={12}>

                <div style={{
                    border: '1px solid #d1d1d1',
                    marginRight: '24px',
                    padding: '16px 24px'
                }}>

                    <div style={{padding: '0px 0px', margin: '0px 0px 8px 0px'}}>
                        <Flex gap="middle">
                            <p style={{width: '50%', margin: '0'}}>Order Status</p>
                            <p style={{width: '50%', margin: '0', textAlign: 'end'}}>
                                <Tag color="#4361ee" style={{fontSize: '14px', padding: '2px 16px'}}>{orderDetails?.orderStatus}</Tag>
                            </p>
                        </Flex>
                    </div>

                    <div style={{width: '100%', borderBottom: '1px dashed #818181', margin: '16px 0px'}}></div>

                    {/*Sub Total*/}
                    {summaryItemText("Order Number", orderDetails?.orderNumber ?? '', '')}

                    <div style={{width: '100%', borderBottom: '1px dashed #818181', margin: '16px 0px'}}></div>

                    {/*Sub Total*/}
                    {summaryItem("Sub Total", orderDetails?.itemsPricesBeforeDiscount ?? 0, 'TZS')}

                    {/*Tax*/}
                    {summaryItem("Tax", orderDetails?.vat ?? 0, 'TZS')}

                    {/*Discount*/}
                    {summaryItem("Discount", orderDetails?.totalDiscountsIssued ?? 0, 'TZS')}

                    {/*Due*/}
                    {totalItem("Total Amount", orderDetails?.amountDue ?? 0, 'TZS')}

                    <div style={{width: '100%', borderBottom: '1px dashed #818181', margin: '16px 0px'}}></div>

                    {summaryItemText("Date", orderDetails?.createdDate ?? '', '')}


                </div>

            </Col>

            <Col span={24} lg={12} md={12}>
                <div style={{
                    border: '1px solid #d1d1d1',
                    marginBottom: '24px',
                    padding: '8px 8px'}}>

                    <h3> Order Items </h3>
                    {orderProducts.map((orderProduct) => {
                        return <OrderProductWidget
                            key={orderProduct.id}
                            orderProduct={orderProduct}></OrderProductWidget>
                    })}

                </div>


            </Col>

        </Row>


    </div>

}

export default OrderDetailsPage

