import React, {useEffect, useState} from 'react';
import {postRequest} from "../../../services/rest/RestService";
import {notifyHttpError} from "../../../services/notification/notifications";
import {Button, Form, Input, Radio, Space} from "antd";

import {
    ArrowLeftOutlined,
    KeyOutlined,
    LinkOutlined,
    MailOutlined,
    MobileOutlined,
    PhoneOutlined,
} from "@ant-design/icons";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setName, setToken} from "../../../state/auth/authStore";
import {isNotEmpty} from "../../../utils/helpers";
import RecommendedActionsDialog from "../../components/RecommendedActionsDialog";
import {AuthChallenges, ServerRecommendedAction} from "../../../interfaces/system/ServerResponseInterfaces";
import MessageCard, {
    buildErrorMessage,
    buildInfoMessage,
    PromptMessage
} from "../../components/MessageCard";
import {IndentifierTypes} from "../../../constants/constants";
import {toBase64Params} from "../../../utils/GoodUrlUtils";
import {useAuthContext} from "../../../state/contexts/AuthContext";

export interface GoodQueryParams {
    email: string | '';
    phone: string | '';
    redirectionUrl: string | ''
}

const OTPPage = () => {

    const [isLoadingAuthChallenges, setLoadingAuthChallenges] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isOtpSent, setOtpSent] = useState(false);
    const [identifierType, setIdentifierType] = useState('PHONE');
    const [credentials, setCredentials] = useState<any>();
    const [queryParams, setQueryParams] = useState<GoodQueryParams>();
    const [recommendedActions, setRecommendedActions] = useState<ServerRecommendedAction[]>([]);
    const [promptMessage, setPromptMessage] = useState<PromptMessage>();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [searchParams] = useSearchParams();
    const query = searchParams.get('query'); // Access specific query param

    const [otpRequestForm] = Form.useForm();
    const [otpVerificationForm] = Form.useForm();
    const {setCustomer} = useAuthContext()


    useEffect(() => {
        const parsedParams = parseUrlParams();
        setDefaultValues(parsedParams)
        getAuthChallenges(parsedParams);
    }, []);

    const getAuthChallenges = (goodQueryParams: GoodQueryParams) => {

        const payload: any = {
            'identifier': goodQueryParams?.phone,
            'identifierType': IndentifierTypes.PHONE,
        };
        const uri = `/api/v1/market/auth/challenges`;
        console.log(`fetching auth challenges... ${uri} ${JSON.stringify(payload)}`);
        setLoadingAuthChallenges(true);
        postRequest(uri, payload).then((response) => {
            console.log(JSON.stringify(response.data))
            setLoadingAuthChallenges(false)
            if (response.data.respCode === 2000) {
                const availableAuthChallenges = (response.data.respBody as AuthChallenges);
                if (availableAuthChallenges.canChallengePassword) {
                    redirectToPasswordLogin(goodQueryParams);
                } else {
                    console.log("can not challenge password")
                }
            }
        }).catch((errorObj) => {
            console.error(errorObj)
            setLoadingAuthChallenges(false)
        }).finally(() => {
            setLoadingAuthChallenges(false)
        })
    }

    const requestOtp = (data: any) => {
        const payload: any = {
            'identifier': data.identifier,
            'identifierType': data.identifierType,
        };
        const uri = `/api/v1/market/auth/challenges/otp/request`;
        console.log(`requesting OTP... ${uri} ${JSON.stringify(payload)}`);

        otpVerificationForm.setFieldsValue({
            ...payload,
            'otp': ''
        })
        setCredentials(payload);
        setIsLoading(true);
        clearMessage();

        postRequest(uri, payload).then((response) => {
            setIsLoading(false)
            if (response.data.respCode === 2000) {
                setOtpSent(true)
            } else {
                setPromptMessage(buildErrorMessage(response.data.message))
            }
        }).catch((errorObj) => {
            console.error(errorObj)
            setIsLoading(false)
            notifyHttpError('Operation Failed', errorObj)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const verifyOtp = (verificationData: any) => {

        setIsLoading(true);
        clearMessage();

        const payload = {
            'otp': verificationData.otp,
            'identifier': verificationData.identifier,
            'identifierType': verificationData.identifierType,
        };

        const uri = `/api/v1/market/auth/login/otp`;
        console.log(`verifying OTP... ${JSON.stringify(payload)} ${uri}`)

        postRequest(uri, payload).then((response) => {
            setIsLoading(false)
            handleOtpVerificationResponse(response.data,verificationData.identifier)
        }).catch((errorObj) => {
            console.error(errorObj)
            setIsLoading(false)
            notifyHttpError('Operation Failed', errorObj)
        }).finally(() => {
            setIsLoading(false)
        })
    };

    const handleOtpVerificationResponse = (otpVerificationResponse: any, identifier: string) => {

        if (otpVerificationResponse.respCode !== 2000) {
            setPromptMessage(buildErrorMessage(otpVerificationResponse.message))
            return;
        }

        /// On successful auth
        const firstName = otpVerificationResponse.respBody.firstName;
        console.log(`login successful... ${otpVerificationResponse.actions}`)
        dispatch(setToken(otpVerificationResponse.respBody.token));
        dispatch(setName(firstName));

        setCustomer({firstName: firstName, identifier:identifier })
        redirectAfterAuth(otpVerificationResponse);
    }

    const onValidationFailed = (values: any) => {
        console.log(`values ${JSON.stringify(values)}`);
    };

    const onIdentifierTypeChange = (item: any) => {
        if (item.target.value == 'EMAIL') {
            otpRequestForm.setFieldValue('identifier', queryParams?.email)
            setIdentifierType('EMAIL')
        } else {
            otpRequestForm.setFieldValue('identifier', queryParams?.phone)
            setIdentifierType('PHONE')
        }
    };

    const redirectAfterAuth = (otpVerificationResponse: any) => {

        /// Show recommended actions if available
        setRecommendedActions(otpVerificationResponse.actions)
        if (recommendedActions.length > 0) {
            console.log("Using recommended action")
            return;
        }

        /// Otherwise redirect
        if (isNotEmpty(queryParams?.redirectionUrl)) {
            navigate(queryParams?.redirectionUrl ?? '/');
        } else {
            navigate('/');
        }
    }

    const redirectToPasswordLogin = (goodQueryParams: GoodQueryParams) => {
        const paramsToForward = toBase64Params(goodQueryParams?.phone ?? '', goodQueryParams?.email ?? '', goodQueryParams?.redirectionUrl)
        navigate(`/auth/login?query=${paramsToForward}`);
    }

    const parseUrlParams = (): GoodQueryParams => {
        if (isNotEmpty(query)) {
            const paramsJsonString = window.atob(query ?? '')
            console.log(`Parsing url params ${paramsJsonString}`)
            return JSON.parse(paramsJsonString)
        }

        console.log(`empty query params...`)
        const defaultParams = {phone: '', email: '', redirectionUrl: ''};
        setQueryParams(defaultParams)
        return defaultParams;
    }

    const setDefaultValues = (customQueryParams: GoodQueryParams) => {
        setQueryParams(queryParams)
        otpRequestForm.setFieldsValue({
            'identifier': customQueryParams?.phone,
            'identifierType': 'PHONE'
        })
    }

    const clearMessage = () => {
        setPromptMessage(buildInfoMessage(''))
    }

    return <div className="container" style={{
        maxWidth: '520px',
        padding: '24px',
        marginTop: "64px",
        borderRadius: '12px',
        border: '1px solid #d1d1d1',
        marginBottom: "64px",
        backgroundColor: "#ffffff"
    }}>


        <div>
            <RecommendedActionsDialog actions={recommendedActions} title="Login Successful"/>

            {/***
             /*  --------------------------
             /* OTP Request Form
             **--------------------------- */}
            {!isOtpSent && <div>

                <h3> Request One Time Passcode </h3>

                <MessageCard promptMessage={promptMessage}/>

                <Form
                    form={otpRequestForm}
                    name="otpRequestForm"
                    initialValues={{
                        identifierType: 'PHONE'
                    }}
                    onFinish={requestOtp}
                    layout="horizontal"
                    onFinishFailed={onValidationFailed}
                    requiredMark={false}
                    colon={false}
                    autoComplete="off"
                >

                    <Form.Item name="identifierType">
                        <Radio.Group onChange={onIdentifierTypeChange}>
                            <Radio value="PHONE">Phone Number</Radio>
                            <Radio value="EMAIL">Email</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item>
                        <Space>
                            <Form.Item
                                name="identifier"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your phone!",
                                    },
                                ]}>
                                <Input size='large'
                                       prefix={identifierType == 'PHONE' ? <MobileOutlined/> : <MailOutlined/>}/>
                            </Form.Item>
                            <Form.Item>
                                <Button size="large" style={{backgroundColor: "#00509d"}}
                                        loading={isLoading} type="primary"
                                        htmlType="submit" block>
                                    Get OTP
                                </Button>
                            </Form.Item>
                        </Space>

                    </Form.Item>

                    <Form.Item>
                        <Button href="/auth/login" size="large" loading={isLoading} type="link" >
                            Use your password instead <KeyOutlined/>
                        </Button>
                    </Form.Item>
                </Form>

            </div>}


            {/***
             /*  --------------------------
             /* OTP Verification Form
             **--------------------------- */}
            {isOtpSent && <div>

                <Button onClick={() => {
                    setOtpSent(false);
                }} loading={isLoading}
                        style={{color: '#00509d'}}
                        icon={<ArrowLeftOutlined/>}
                        type="default">
                    Retry
                </Button>

                <MessageCard promptMessage={promptMessage}/>

                <p style={{marginTop: '16px'}}> We have sent a One Time Pin (OTP) to
                    you {credentials?.identifierType == 'PHONE' ? 'phone' : 'email'} {credentials?.identifier}
                </p>

                <h3 style={{
                    margin: '0 0 32px 0'
                }}> Enter received OTP </h3>

                <Form
                    form={otpVerificationForm}
                    name="otpVerificationForm"
                    onFinish={verifyOtp}
                    layout="horizontal"
                    onFinishFailed={onValidationFailed}
                    requiredMark={false}
                    colon={false}
                    autoComplete="off"
                >

                    <Form.Item name="identifierType" hidden>
                        <Radio.Group>
                            <Radio.Button value="PHONE">Phone Number</Radio.Button>
                            <Radio.Button value="EMAIL">Email</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item hidden>
                        <Space>
                            <Form.Item
                                name="identifier"
                                rules={[{required: true, message: "Please input your phone!"},
                                ]}>
                                <Input size='large' prefix={<PhoneOutlined/>}/>
                            </Form.Item>
                            <Form.Item>
                                <Button size="large" style={{backgroundColor: "#00509d"}}
                                        loading={isLoading} type="primary"
                                        htmlType="submit" block>
                                    Get OTP
                                </Button>
                            </Form.Item>
                        </Space>
                    </Form.Item>

                    <Form.Item name="otp">
                        <Input.OTP size='large'/>
                    </Form.Item>

                    <Button size="large" style={{backgroundColor: "#00509d"}}
                            loading={isLoading} type="primary"
                            htmlType="submit" block>
                        Submit
                    </Button>
                </Form>

            </div>}
        </div>

    </div>

}

export default OTPPage

