import React, {useEffect, useState} from 'react';


import {MessageType, toRecommendedRedirection} from "../../constants/constants";
import {isNotEmpty} from "../../utils/helpers";
import {ArrowRightOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {ServerRecommendedAction} from "../../interfaces/system/ServerResponseInterfaces";
import {List, Modal} from "antd";
import {useNavigate} from "react-router-dom";

interface RecommendedActionsProps {
    actions: ServerRecommendedAction[],
    title: string | ''
}

const RecommendedActionsDialog = ({actions,title} : RecommendedActionsProps ) => {

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const navigate = useNavigate();
    useEffect(() => {
        if(actions.length>0){
            setIsModalOpen(true)
        }
    }, [actions]);


    const onActionClick = (code:string) => {
        const uri = toRecommendedRedirection(code);
        navigate(uri)
    }


    return <>
        <div>
            <Modal
                   title={title}
                   closable={true}
                   width={400}
                   open={isModalOpen}
                   footer={<></>}
                   onCancel={()=>{ setIsModalOpen(false)}}>

                <List
                    style={{ marginTop: '24px'}}
                    className="demo-loadmore-list"
                    loading={false}
                    itemLayout="horizontal"
                    dataSource={actions}
                    renderItem={(serverRecommendedAction) => (
                        <List.Item
                            style={{
                                padding: '8px 12px',
                                margin: '0 0 16px 0',
                                cursor: 'pointer',
                                border: '1px solid #7fb5f4',
                                borderRadius: '8px',
                                backgroundColor: '#edf4fd'
                            }}
                            actions={[<ArrowRightOutlined/>]}
                            onClick={()=>{
                                onActionClick(serverRecommendedAction.code)
                            }}>

                            <div>
                                <h3 style={{
                                    margin: '0 0',
                                    fontWeight: 'normal',
                                    }}> {serverRecommendedAction.title}
                                </h3>

                                <p style={{
                                    margin: '0 0',
                                    color: '#4895ef'}}>
                                    {serverRecommendedAction.narration}
                                </p>
                            </div>

                        </List.Item>

                    )}
                />
            </Modal>
        </div>
    </>;
}

export default RecommendedActionsDialog

