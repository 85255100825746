import { notification } from "antd";
import {CheckCircleFilled, InfoCircleFilled} from "@ant-design/icons";


export  function notifySuccess(title='Success', msg ){
    notification['info']({
        icon : <CheckCircleFilled style={{color: '#16db65'}}/>,
        style: { border: '1px solid #16db65', borderRadius: '6px'},
        message: title,
        description: msg,
        onClick: () => {
            console.log('Notification Clicked!');
        },
    });
}

export  function notifyError(title='Error', msg ){
    notification['error']({
        icon : <InfoCircleFilled style={{color: '#fdc500'}}/>,
        style: { border: '1px solid #fdc500', borderRadius: '6px'},
        message: title,
        description: msg,
        onClick: () => {
            console.log('Notification Clicked!');
        },
    });
}

export  function notifyHttpError(title='Request Failed', errorObject = { } ){

    const msg = `${errorObject.errorMessage}. ${errorObject.respCode}`;
    notification['error']({
        icon : <InfoCircleFilled style={{color: '#fdc500'}}/>,
        style: { border: '1px solid #fdc500', borderRadius: '6px'},
        message: title,
        description: msg,
        onClick: () => {
            console.log('Notification Clicked!');
        },
    });
}


export  function notifyFormErrors(title='Invalid Inputs', errorData = [] ){

    console.warn("notifyFormErrors(): ", JSON.stringify(errorData))

    let msg = ""
    for (let i = 0; i < errorData.length; i++) {
        msg = msg + (i+1)+"."+errorData[i].errors[0] +", "
    }

    notification['warn']({
        message: title,
        description: msg,
        onClick: () => {
            console.log('Notification Clicked!');
        },
    });

}
