
import { Flex } from 'antd';
import React from 'react';

interface WidgetProps {
    icon?: React.ReactNode,
    label: string | '',
    value: string | ''
    suffix?: string | ''
}

const GoodListItemWidget = ( widgetProps : WidgetProps) => {


    return <>
        <div style={{padding: '0px 0px', margin: '0px 0px 8px 0px'}}>
            <Flex gap="middle">
                {widgetProps.icon}
                <p style={{width: '50%', margin: '0'}}>{widgetProps?.label}</p>
                <p style={{width: '50%', margin: '0', textAlign: 'end'}}>
                    {(widgetProps.value)} {widgetProps.suffix}
                </p>
            </Flex>
        </div>
    </>;
}

export default GoodListItemWidget

