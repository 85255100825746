import "../../css/App.css";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import MainLayout from "../MainLayout";
import React from "react";
import Logout from "../../services/auth/Logout";
import {MerchantProvider} from "state/contexts/MerchantContext";
import LoginPage from "ui/pages/auth/LoginPage";

function AppRouter() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>

                    <Route path="/logout" element={<Logout><LoginPage/></Logout>}/>
                    <Route path="/*" element={<MerchantProvider><MainLayout/></MerchantProvider>}> </Route>

                </Routes>

            </BrowserRouter>
        </div>
    );
}

export default AppRouter;
