import {Layout} from 'antd';
import React, {useEffect, useState} from "react";
import 'css/custom.css';
import {Route, Routes} from "react-router-dom";

import {getRequest} from "../services/rest/RestService";
import {notifyHttpError} from "../services/notification/notifications";
import ProductsListPage from "./pages/products/ProductsListPage";
import CartPage from "./pages/cart/CartPage";
import ProductDetailsPage from "./pages/products/ProductsDetailsPage";
import {useMerchantContext} from "state/contexts/MerchantContext";
import {CartProvider} from "state/contexts/CartContext";
import GoodHeader from "./GoodHeader";
import CheckoutPage from "./pages/cart/CheckoutPage";
import OrderDetailsPage from "./pages/orders/details/OrderDetailsPage";
import {AuthProvider} from "../state/contexts/AuthContext";
import OTPPage from "./pages/auth/OTPPage";
import LoginPage from "./pages/auth/LoginPage";
import PasswordCreationPage from "./pages/auth/PasswordCreationPage";
import OrdersListPage from "./pages/orders/OrdersListPage";
import ProfilePage from "./pages/profile/ProfilePage";
import GoodFooter from "ui/GoodFooter";
import {SearchProvider} from "state/contexts/SearchContext";
import MerchantContactsPage from "ui/pages/business/MerchantContactsPage";

const {Content} = Layout;

function MainLayout() {

    // @ts-ignore
    return (
        <AuthProvider>
            <CartProvider>
                <SearchProvider>
                <Layout className="dotted-background"
                    style={{
                        minHeight: '50vh',
                        backgroundColor: '#f4f7f8'
                }}>

                    <GoodHeader></GoodHeader>

                    <Content style={{ minHeight:'50vh', padding: '0 0'}}>

                        <Routes>
                            {/* Products & Cart */}
                            <Route path="products/:productId" element={<ProductDetailsPage/>}/>
                            <Route path="cart" element={<CartPage/>}/>
                            <Route path="checkout" element={<CheckoutPage/>}/>

                            {/* Profile */}
                            <Route path="profile" element={<ProfilePage/>}/>
                            <Route path="orders" element={<OrdersListPage/>}/>
                            <Route path="orders/:orderId" element={<OrderDetailsPage/>}/>

                            {/* Orders */}
                            <Route path="auth/otp" element={<OTPPage/>}/>
                            <Route path="auth/login" element={<LoginPage/>}/>
                            <Route path="auth/password/set" element={<PasswordCreationPage/>}/>
                            <Route path="auth/password/change" element={<PasswordCreationPage/>}/>

                            {/* Business */}
                            <Route path="contacts" element={<MerchantContactsPage/>}/>

                            <Route path="*" element={<ProductsListPage/>}/>
                        </Routes>

                    </Content>

                    <GoodFooter/>

                </Layout>
                </SearchProvider>
            </CartProvider>
        </AuthProvider>
    );
}

export default MainLayout






