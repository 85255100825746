import axiosClient from "./axios";
import {codeStartsWith, isNotEmpty} from "utils/helpers";
import {getStoredUserToken, USER_KEY} from "state/auth/authStore";
import {logWarning} from "utils/GoodLogger";

const removeLoggedUser = () => {
    console.log("401 removing expired token")
    localStorage.removeItem(USER_KEY);
    window.location.replace(window.location.host)
}

const getCurrentPath = () => {
    const currentPath = window.location.pathname;
    const queryParams = window.location.search;
    return currentPath + queryParams;
}

const redirectToLogin = () => {

    const phone = localStorage.getItem('phone');
    const email = localStorage.getItem('email');
    const rawParams = JSON.stringify({phone: phone, email: email, redirectionUrl: getCurrentPath()})

    const base64Params = window.btoa(rawParams);
    const fullHttpHost = window.location.protocol + '//' + window.location.host;
    const redirection = fullHttpHost + `/auth/otp?query=${base64Params}`;
    console.log('callbackUrl', redirection)
    window.location.replace(redirection)
}

const toErrorObject = (error) => {

    let errorObject = {
        errorMessage: '',
        errorHttpCode: '',
        respCode: '',
        extraInfo: ''
    };

    if (isNotEmpty(error.response)) {

        errorObject.errorHttpCode = error.response.status;

        if (isNotEmpty(error.response.data)) {
            const backendResp = error.response.data;
            errorObject.errorMessage = backendResp.message;
            errorObject.respCode = backendResp.respCode;
            errorObject.extraInfo = backendResp.respBody ? backendResp.respBody : '';
        } else {
            errorObject.errorMessage = "Request Failed";
            errorObject.respCode = "Unknown";
            errorObject.extraInfo = "";
        }

    } else {

        console.log(JSON.stringify(error))
        errorObject.errorMessage = error.message + '. Request failed! We don\'t know much about what happened. Make Sure the server is reachable and you are connected to the internet';
        errorObject.respCode = '';
        errorObject.extraInfo = '';
    }

    return errorObject;
}

const toErrorMessage = (msg, httpCode, respCode, extraInfo) => {

    return {
        errorMessage: msg,
        errorHttpCode: httpCode,
        respCode: respCode,
        extraInfo: extraInfo
    };

}


export function getRequest(uri) {

    console.log(`${axiosClient.defaults.baseURL}${uri}`)

    const userToken = getStoredUserToken();
    const authToken = isNotEmpty(userToken) ? `Bearer ${userToken}` : '';
    axiosClient.defaults.headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': authToken
    }

    return new Promise((resolve, reject) => {

        return axiosClient.get(`${uri}`)
            .then(response => {

                if (response.data != null && response.data.respCode != null) {

                    /// Catch server errors
                    if (codeStartsWith(response.data.respCode, '5')) {
                        logWarning(`🌐server could not process the request code: ${response.data.respCode}  "${response.data.message}"`)
                        reject(toErrorMessage('Sorry, We failed to process the request', 555, 5001, 'Please contact us for more assistance'))
                        return;

                        /// Handle unauthenticated
                    } else if (response.data.respCode === 4001) {
                        logWarning(`🌐endpoint required authentication ${response.data.respCode}  ${response.data.message}`)
                        removeLoggedUser();
                        redirectToLogin();
                        reject(toErrorMessage('Sorry, session expired', 555, 4001, 'Please contact us for more assistance'))
                        return;
                    }
                }

                /// Resolve successful
                resolve(response);

            }).catch(error => {
                const customErrorObject = toErrorObject(error);
                if (customErrorObject.errorHttpCode === 401) {
                    removeLoggedUser();
                }
                reject(customErrorObject);
            });
    });

}

export function postRequest(uri, payload) {

    console.log(`${axiosClient.defaults.baseURL}${uri}`)

    const userToken = getStoredUserToken();
    const authToken = isNotEmpty(userToken) ? `Bearer ${userToken}` : '';
    axiosClient.defaults.headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': authToken
    }

    return new Promise((resolve, reject) => {

        axiosClient.post(`${uri}`, payload)
            .then(response => {

                if (response.data == null || response.data.respCode == null) {
                    resolve(response);
                    return;
                }

                const respCode = response.data.respCode;

                /// Catch server errors
                if (codeStartsWith(respCode, '5')) {
                    logWarning(`🌐server could not process the request code: ${response.data.respCode}  "${response.data.message}"`)
                    reject(toErrorMessage('Sorry, We failed to process the request', 555, 5555, 'Please contact us for more assistance'))
                    return;
                }

                /// Handle unauthenticated
                if (respCode === 4001) {
                    logWarning(`🌐endpoint required authentication ${response.data.respCode}  ${response.data.message}`)
                    removeLoggedUser();
                    reject(toErrorMessage('Sorry, session expired', 444, 4444, 'Please contact us for more assistance'))
                    return;
                }

                resolve(response);

            }).catch(error => {

            const customErrorObject = toErrorObject(error);
            if (customErrorObject.errorHttpCode === 401) {
                removeLoggedUser();
                redirectToLogin();
            }
            reject(customErrorObject);
        });
    });

}


export function deleteRequest(uri) {
    return axiosClient.delete(`${uri}`).then(response => response);
}
