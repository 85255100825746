import React from 'react';


import {MessageType} from "../../constants/constants";
import {isNotEmpty} from "../../utils/helpers";
import {InfoCircleOutlined} from "@ant-design/icons";

export interface PromptMessage {
    type: MessageType | MessageType.Info;
    msg: string | '';
}

export function buildInfoMessage(message: string): PromptMessage {
    return {type: MessageType.Info, msg: message}
}
export function buildErrorMessage(message: string): PromptMessage {
    return {type: MessageType.Error, msg: message}
}

const MessageCard = ({promptMessage}: any) => {

    const getBorderColor = () => {
        if (promptMessage?.type == MessageType.Error) {
            return '#e26969';
        }
        return '#c4e3fe';
    }

    const getBackgroundColor = () => {
        console.log(JSON.stringify(promptMessage?.type))
        if (promptMessage?.type === MessageType.Error) {
            return '#fbeaea';
        }
        return '#ebf6ff';
    }

    return <>
        <div>

            {isNotEmpty(promptMessage?.msg) &&
                <p style={{
                    borderRadius: '8px',
                    backgroundColor: getBackgroundColor(),
                    border: `1px solid ${getBorderColor()}`,
                    color: '#4c5a66',
                    padding: '16px 12px'
                }}>
                    <InfoCircleOutlined style={{marginRight: '8px'}} color='#4c5a66'></InfoCircleOutlined>
                    {promptMessage?.msg ?? ''}
                </p>
            }

        </div>
    </>;
}

export default MessageCard

