import React, {useState, useEffect} from 'react';
import {getRequest} from "services/rest/RestService";
import {notifyHttpError} from "services/notification/notifications";
import {Flex, Row, Tag} from "antd";

import {Category} from "interfaces/ProductInterfaces";
import {useMerchantContext} from "state/contexts/MerchantContext";
import {isEmpty} from "utils/helpers";
import {useSearchContext} from "state/contexts/SearchContext";
import 'css/products.css';

const CategoriesWidget = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [topLevelCategories, setTopLevelCategories] = useState<Category[]>([]);
    const {merchant} = useMerchantContext();
    const {queryTerm,topLevelCategory,setTopLevelCategory,setSearchTerm} = useSearchContext();

    useEffect(() => {
        fetchCategories();
    }, [merchant, queryTerm]);

    const fetchCategories = ( categoryLevel = 1) => {

        if (isEmpty(merchant?.id)) {
            console.log("merchant id is empty...")
            return;
        }

        const uri = `/api/v1/market/public/products/categories?businessId=${merchant?.id}&level=${categoryLevel}`;
        getRequest(uri)
            .then((response) => {
                setIsLoading(false)
                setTopLevelCategories(response.data.items)
            }).catch((errorObj) => {
            notifyHttpError('Some assets could not be loaded. Refresh the page', errorObj)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const onCategoryChange = (category: Category, isSelected: boolean) => {
        if (isSelected) {
            setSearchTerm("");
            setTopLevelCategory(category);
        }else{
            setTopLevelCategory(undefined)
        }
    }

    return <div className="container categories-container">

        <Flex gap={4} wrap align="initial">
            {topLevelCategories.map<React.ReactNode>((category) => (
                <Tag
                    key={category.id}
                    className={`category-item ${topLevelCategory===category?'category-selected':''}`}
                    onClick={(checked) => onCategoryChange(category, !(topLevelCategory===category))}
                >
                    {category?.name??""}
                </Tag>
            ))}
        </Flex>

    </div>;

}

export default CategoriesWidget

