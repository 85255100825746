import React, {createContext, useContext, useEffect, useState} from 'react';
import {CartProduct} from "interfaces/ProductInterfaces";
import {isNotEmpty} from "utils/helpers";

interface CartContextProps {
    cartProducts: CartProduct[];
    addCartProduct: (cartProduct: CartProduct) => void;
    removeFromCart: (cartProduct: CartProduct) => void;
    clearCart: () => void;
}

const CartContext = createContext<CartContextProps | undefined>(undefined);

export const CartProvider = ({children}: any) => {

    const [cartProducts, setCartProducts] = useState<CartProduct[]>([]);

    useEffect(() => {
        console.log("Loading Cart");
        // Load cart products from local storage
        const storedCartJson = localStorage.getItem("cart");
        if (isNotEmpty(storedCartJson)) {
            setCartProducts(JSON.parse(storedCartJson ?? "[]"));
        }
    }, []); // Empty dependency array ensures this effect runs only once on component mount


    const addToCart = (cartProductInstance: CartProduct) => {
        const items = cartProducts.filter(item => item.productVariant?.id !== cartProductInstance.productVariant?.id)
        items.push(cartProductInstance);
        const newList = [...items];
        setCartProducts(newList)
        localStorage.setItem("cart", JSON.stringify(newList))
    }

    const removeFromCart = (cartProductInstance: CartProduct) => {
        const indexToRemove = cartProducts.indexOf(cartProductInstance)
        cartProducts.splice(indexToRemove, 1);
        const newList = [...cartProducts];
        setCartProducts(newList)
        localStorage.setItem("cart", JSON.stringify(newList))
    }

    const clearCart = () => {
        setCartProducts([])
        localStorage.setItem("cart", JSON.stringify([]))
    }

    return (
        <CartContext.Provider
            value={{cartProducts: cartProducts, addCartProduct: addToCart, removeFromCart, clearCart}}>
            {children}
        </CartContext.Provider>
    );
};

export const useCartContext = () => {
    const context = useContext(CartContext);
    if (!context) {
        throw new Error('useCart must be used within a CartProvider');
    }
    return context;
};

export default CartContext;
