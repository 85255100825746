
import {Spin} from 'antd';
import React from 'react';
import {LoadingOutlined} from "@ant-design/icons";

interface WidgetProps {
    isLoading: boolean | false
}

const GoodLoadingWidget = ( widgetProps : WidgetProps) => {

    return <>
        <Spin spinning={widgetProps?.isLoading}
              indicator={<LoadingOutlined style={{ fontSize: 20 }} spin/>}
        />
    </>;
}

export default GoodLoadingWidget

