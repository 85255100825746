import React, {useState, useEffect} from 'react';
import {getRequest} from "../../../services/rest/RestService";
import {notifyHttpError} from "../../../services/notification/notifications";
import {Col, Row} from "antd";
import {useMerchantContext} from "state/contexts/MerchantContext";
import {isEmpty} from "../../../utils/helpers";
import {Order} from "../../../interfaces/OrderInterfaces";
import {ArrowRightOutlined, ShoppingCartOutlined, TruckOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import OrderItemWidget from "./OrderItemWidget";

const OrdersListPage = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [orderList, setOrdersList] = useState<Order[]>([]);
    const {merchant} = useMerchantContext()
    const navigate = useNavigate()

    //Fetch products
    useEffect(() => {
        fetchOrders();
    }, [merchant]);

    //Fetch Packages
    const fetchOrders = () => {
        if (isEmpty(merchant.id)) {
            return;
        }
        setIsLoading(true)
        console.log(`fetching orders...`)
        getRequest(`/api/v1/market/customer/orders/history?businessId=${merchant?.id}`)
            .then((response) => {
                setIsLoading(false)
                console.log(JSON.stringify(response.data));
                setOrdersList(response.data.items)
            }).catch((errorObj) => {
            setIsLoading(false)
            notifyHttpError('Sorry, request failed. Try again later', errorObj)
        }).finally(() => {
            setIsLoading(false)
        })
    }


    const hasOrders = () : boolean => {
        return orderList.length>0
    }


    return <div className="container" style={{
        maxWidth: '1080px',
        paddingLeft: '16px',
        borderRadius: '8px',
        padding: '16px',
        marginTop: "64px",
        marginBottom: "64px",
        backgroundColor: "#ffffff"
    }}>


        <Row gutter={16}>
            <Col lg={18} md={14} sm={24}>
                <div style={{border: '1px solid #d1d1d1', padding: '16px 16px'}}>
                    <h3> <TruckOutlined/> My Orders </h3>

                    {orderList.map((orderInstance) => {
                        return <OrderItemWidget
                            key={orderInstance.id}
                            order={orderInstance}></OrderItemWidget>
                    })}

                    { !hasOrders() &&  <p>
                        You do not have any orders
                    </p>}

                </div>
            </Col>

        </Row>

    </div>;

}

export default OrdersListPage

