import React  from 'react';

import {useMerchantContext} from "state/contexts/MerchantContext";
import contactsWoman from 'assets/images/pages/contacts/contacts.jpg'
import {Col, Row} from "antd";
import 'css/contacts.css';
import {MailOutlined, PhoneOutlined, ShopOutlined} from "@ant-design/icons";

const MerchantContactsPage = () => {

    const {merchant} = useMerchantContext()

    return <div className="container" style={{
        maxWidth: '840px',
        padding: '24px',
        marginTop: "64px",
        borderRadius: '12px',
        border: '1px solid #d1d1d1',
        marginBottom: "64px",
        backgroundColor: "#ffffff"
    }}>


        <div>

            <Row>
                <Col span={24} lg={12}>

                    <h3> Reach Us Now </h3>
                    <h3 className="merchant-name"> {merchant?.name} </h3>

                    <ul className="business-info-holder">
                        <li className="business-info-item">
                            <a href={`tel:${merchant?.phoneNumber}`}>
                                <PhoneOutlined style={{marginRight: '12px'}}/>
                                {merchant?.phoneNumber}
                            </a>
                        </li>

                        <li className="business-info-item">
                            <a href={`mailto:${merchant?.email}`}>
                                <MailOutlined style={{marginRight: '12px'}}/>
                                {merchant?.email}
                            </a>
                        </li>
                        <li className="business-info-item">
                            <a href={merchant?.googleMapLink ?? '/'}>
                                <ShopOutlined style={{marginRight: '12px'}}/>
                                {merchant?.physicalAddress}
                            </a>
                        </li>
                    </ul>

                    <p style={{fontWeight: 'lighter'}}>
                        {merchant?.description}
                    </p>

                </Col>

                <Col span={24} lg={12}>
                    <img className="support-person" src={contactsWoman} alt={merchant?.name}/>
                </Col>

            </Row>

        </div>

    </div>

}

export default MerchantContactsPage

