import { createSlice } from '@reduxjs/toolkit'
import {isEmpty, isNotEmpty} from "../../utils/helpers";

export const USER_KEY = "loggedUser";
export const DEFAULT_USER = { name: "", token: ""};

const saveUserToStorage = (userObject) => {
    console.info(`Persisting user...  `)
    localStorage.setItem(USER_KEY,JSON.stringify(userObject));
}

export const  loadUserFromStorage = () => {
    try {
        const storedAuthState = localStorage.getItem(USER_KEY);
        if(isEmpty(storedAuthState)) {
            return DEFAULT_USER;
        }
        return JSON.parse(localStorage.getItem(USER_KEY))
    }catch (e){
        console.log(e)
       return  DEFAULT_USER;
    }
}

export const getStoredUserToken = () => {

    const user = loadUserFromStorage();
    if(isNotEmpty(user)){
        if(isNotEmpty(user?.token)){
            console.log(`%clocal-user: ${user?.name}. has token`,'color: green')
        }else{
            console.warn(`local-user: ${user?.name}. NO TOKEN`,)
        }
        return user.token;
    }else{
        console.warn("User not found on local storage")
    }

    return '';
}

export const getStoredUser = () => {

    const user = loadUserFromStorage();
    if(isNotEmpty(user)){
        return user;
    }else{
        console.warn("User not found on local storage")
    }

    return {};
}

export const authSlice = createSlice({
    name: "auth",
    initialState:  loadUserFromStorage(),
    reducers: {

        setToken: (state,action)=>{
            state.token = action.payload;
            saveUserToStorage(state)
        },

        setName: (state,action)=>{
            state.name = action.payload;
            saveUserToStorage(state)
        },

        clearSession: (state)=>{
            console.log('Logging out');
            state.name = '';
            state.token = '';
            localStorage.clear();
        }
    }
})

export const { setToken } = authSlice.actions;

export const { setName } = authSlice.actions;

export const { clearSession } = authSlice.actions;

export default authSlice.reducer;
