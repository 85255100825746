import {Image} from 'antd';
import React from 'react';
import {Product} from "interfaces/ProductInterfaces";
import {useNavigate} from "react-router-dom";
import {isEmpty} from "utils/helpers";
import productPlaceholder from "assets/images/icons/product.png";

interface ProductProps {
    product: Product
}

const ProductWidget = ({product}: ProductProps) => {

    const navigate = useNavigate()
    const goToProduct = () => {
        navigate(`/products/${product.id}`);
    }

    return <>
        <div
            onClick={goToProduct}
            className="product-item-holder">
            <Image
                style={{
                    border: '1px solid #f1f1f1'
                }}
                preview={false}
                width="100%"
                src={isEmpty(product.thumbnailUrl) ? productPlaceholder : product.thumbnailUrl}
                alt={product.name}></Image>

            <ul className="product-item-info-ul">
                <li>  {product.name} </li>
                <li className="product-info-lighter">  {product.availableUnits} Remaining</li>
                <li style={{fontSize: '1.4', fontWeight: 'bold', color: '#fcbf49'}}>
                    TZS {(product.minPrice).toLocaleString()}
                </li>
            </ul>

        </div>
    </>;
}

export default ProductWidget

