import {Card, Checkbox, Col, Image, Row, Space, Table, Tag} from 'antd';
import React from 'react';
import productPlaceholder from "../../../assets/images/icons/product.png";
import {CartProduct} from "../../../interfaces/ProductInterfaces";
import {isEmpty} from "../../../utils/helpers";
import {Order} from "../../../interfaces/OrderInterfaces";
import {useNavigate} from "react-router-dom";


interface WidgetProps {
    order: Order
}

const OrderItemWidget = ({order}: WidgetProps) => {

    const navigate = useNavigate()
    const onOrderClick = () => {
        navigate(`/orders/${order?.orderNumber}`)
    }

    return <>
        <div
            onClick={onOrderClick}
            style={{
                cursor:'pointer',
                border:  '1px solid #a1a1a1'  ,
                margin: '16px 8px',
                padding: '8px 8px'
            }}>

            <Space>

                <Image
                    style={{
                        border: '1px solid #a1a1a1'
                    }}
                    preview={false}
                    width="96px"
                    src={isEmpty(order.thumbnailNail1) ? productPlaceholder : order.thumbnailNail1}
                    alt={order.productNames}></Image>

                <div>
                    <p style={{
                        padding: '0px 0px',
                        margin: '2px 0px 4px 0px',
                        fontWeight: 'bold',
                        color: '#2a2a2a'
                    }}>
                        TZS {(order.amountDue).toLocaleString()}
                    </p>
                    <p>
                        {order.productNames}
                    </p>
                </div>
            </Space>


        </div>
    </>;
}

export default OrderItemWidget

