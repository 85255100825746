import React, {createContext, useContext, useState} from 'react';
import {Category} from "@interfaces/ProductInterfaces";

interface SearchContextProp {
    queryTerm: string;
    setSearchTerm: (term: string) => void;

    topLevelCategory: Category | undefined;
    setTopLevelCategory: (cat?: Category) => void;
}

const SearchContext = createContext<SearchContextProp | undefined>(undefined);

export const SearchProvider = ({children}: any) => {

    const [searchTerm, updateSearchTerm] = useState<string>('');
    const [category, updateCategory] = useState<Category>();

    const setNewSearchTerm = (term: string) => {
        console.log(`updating ${term}...`)
        updateCategory(undefined);
        updateSearchTerm(term)
    }

    const onUpdateCategory = (cat?: Category) => {
        updateSearchTerm('')
        updateCategory(cat)
    }

    return (
        <SearchContext.Provider value={{
            queryTerm: searchTerm,
            setSearchTerm: setNewSearchTerm,

            topLevelCategory: category,
            setTopLevelCategory: onUpdateCategory
        }}>
            {children}
        </SearchContext.Provider>
    );
};

export const useSearchContext = () => {
    const context = useContext(SearchContext);
    if (!context) {
        throw new Error('useSearchContext must be used within a SearchProvider');
    }
    return context;
};

export default SearchContext;
