import React from 'react';
import '../css/footer.css';
import {
    EnvironmentFilled, EnvironmentOutlined,
    FacebookOutlined, GlobalOutlined,
    InstagramOutlined, MailOutlined, PhoneOutlined, ShopOutlined,
    TwitterOutlined,
    WhatsAppOutlined
} from "@ant-design/icons";
import {useMerchantContext} from "state/contexts/MerchantContext";

const GoodFooter = () => {

    const {merchant} = useMerchantContext()

    return (
        <footer className="footer">

            <div className="footer-container">

                <div className="footer-section about">
                    <a href="/">
                        <img className="logo footer-logo" src={merchant?.logoUrl} alt={merchant?.name}/>
                    </a>

                    <h2 className="header-section-head">
                    {merchant.name}
                    </h2>
                    <p style={{fontWeight: 'lighter'}}>{merchant?.description}</p>
                </div>

                <div className="footer-section links">
                    <h2 className="header-section-head">Quick Links</h2>
                    <ul style={{padding: 0, margin: 0}}>
                        <li className="footer-quick-link"><a href="/">Home</a></li>
                        <li className="footer-quick-link"><a href="/contacts">Contact Us</a></li>
                    </ul>
                </div>

                <div className="footer-section social">
                    <h2 className="header-section-head">Connect With Us</h2>

                    <div className="" >
                        <ul style={{padding: 0, margin: 0}}>
                            <li className="footer-quick-link">
                                <a href={`tel:${merchant?.phoneNumber}`}>
                                    <PhoneOutlined style={{ marginRight: '12px' }}/>
                                    {merchant?.phoneNumber}
                                </a>
                            </li>

                            <li className="footer-quick-link">
                                <a href={`mailto:${merchant?.email}`}>
                                    <MailOutlined style={{ marginRight: '12px' }}/>
                                    {merchant?.email}
                                </a>
                            </li>
                            <li className="footer-quick-link">
                                <a href={merchant?.googleMapLink??'#'}>
                                    <ShopOutlined style={{ marginRight: '12px' }}/>
                                    {merchant?.physicalAddress}
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="social-icons" style={{marginTop: '16px'}}>
                        <a href={merchant?.googleMapLink ?? '#'}><EnvironmentOutlined/></a>
                        <a href={merchant?.whatsappLink ?? '#'}><WhatsAppOutlined/></a>
                        <a href={merchant?.instagramLink ?? '#'}><InstagramOutlined/></a>
                        <a href={merchant?.twitterLink ?? '#'}><TwitterOutlined/></a>
                        <a href={merchant?.facebookLink ?? '#'}><FacebookOutlined/></a>
                    </div>
                </div>
            </div>

            <div className="footer-bottom">
                <a style={{ color: '#0077b6'}} href="https://rixar.co.tz/hesabu/download">Powered By Hesabu</a>
            </div>
        </footer>
    );
};

export default GoodFooter;
