import React, {useState, useEffect} from 'react';
import {getRequest} from "services/rest/RestService";
import {notifyHttpError} from "services/notification/notifications";
import {Button, Col, Flex, Form, Image, Input, Modal, Row, Space} from "antd";

import {
    ArrowRightOutlined,
    CheckCircleFilled, CheckCircleOutlined,
    MinusOutlined, PlusOutlined, ProductOutlined, ShoppingCartOutlined
} from "@ant-design/icons";
import {useNavigate, useParams} from "react-router-dom";
import {CartProduct, Product, ProductVariant} from "interfaces/ProductInterfaces";
import ProductVariantWidget from "./components/ProductVariantWidget";
import {isEmpty} from "utils/helpers";
import productPlaceholder from "assets/images/icons/product.png";
import isNumeric from "antd/es/_util/isNumeric";
import {useCartContext} from "state/contexts/CartContext";
import {useMerchantContext} from "state/contexts/MerchantContext";
import cartIcon from "assets/images/icons/cart.png";
import {isVisible} from "utils/GoodCSSUtils";

import 'css/products.css';

const ProductDetailsPage = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [isConfirmationModalVisible, setCartConfirmationModal] = useState(false);
    const [selectedQuantity, setQuantity] = useState(1);
    const [product, setProduct] = useState<Product | undefined>(undefined);
    const [selectedProductVariant, setSelectedProductVariant] = useState<ProductVariant | undefined>(undefined);

    const navigate = useNavigate();
    const [cartForm] = Form.useForm();
    const {productId} = useParams();
    const {merchant, setMerchant} = useMerchantContext()

    const {addCartProduct} = useCartContext()

    useEffect(() => {
        fetchProductDetails();
    }, [merchant]);

    const fetchProductDetails = () => {

        if (isEmpty(merchant.id)) {
            console.log('merchant id is empty...')
            return;
        }
        const uri = `/api/v1/market/public/products/details?id=${productId}&merchantId=${merchant.id}`;
        console.log(`fetching products details... ${uri}`)

        setIsLoading(true)
        getRequest(uri).then((response) => {
            setIsLoading(false)
            setProduct(response.data.respBody);
            setDefaultProductVariant(response.data.respBody)
        }).catch((errorObj) => {
            console.error(errorObj)
            setIsLoading(false)
            notifyHttpError('Operation Failed', errorObj)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const setDefaultProductVariant = (loadedProduct: Product) => {
        if (loadedProduct.productVariantList === undefined) {
            console.log("No product variant list");
            return;
        }

        if (loadedProduct.productVariantList.length === 0) {
            console.log("empty product variant list");
            return;
        }

        setSelectedProductVariant(loadedProduct.productVariantList[0])
    }

    const isVariantSelected = (variant: ProductVariant): boolean => {
        if (selectedProductVariant == undefined) {
            return false;
        }
        return variant.id === selectedProductVariant?.id;
    }

    const onSelectionChange = (variant: ProductVariant, isSelected: boolean) => {
        if (variant === selectedProductVariant) {
            return;
        }
        setSelectedProductVariant(variant);
    }

    const onAddToCart = (isCheckout: boolean) => {
        const cartProduct: CartProduct = {
            quantity: selectedQuantity,
            product: product!,
            productVariant: selectedProductVariant!,
            remark: ''
        };

        addCartProduct(cartProduct)

        if (isCheckout) {
            navigateToCheckout();
        } else {
            setCartConfirmationModal(true);
        }
    }

    const onQuantityChange = (value: any) => {

        const val = value.target.value;
        if (isEmpty(val)) {
            setQuantity(1);
            cartForm.setFieldValue('quantity', 1);
            return;
        }

        if (isNumeric(val)) {
            const qty = Math.round(val);
            setQuantity(qty)
            cartForm.setFieldValue('quantity', qty);
        }

    }

    const increment = () => {
        console.log('quantity increment ~~', selectedQuantity)
        setQuantity(selectedQuantity + 1);
        cartForm.setFieldValue('quantity', selectedQuantity);
    }

    const decrement = () => {
        if (selectedQuantity <= 1) {
            setQuantity(1);
            cartForm.setFieldValue('quantity', 1);
            return;
        }

        const qty = selectedQuantity - 1;
        setQuantity(qty);
        cartForm.setFieldValue('quantity', qty);
    }

    const navigateToCheckout = () => {
        navigate("/cart");
    }

    const hasMultipleVariants = () => {
        return (product?.productVariantList?.length??0)>1;
    }


    return <div className="container" style={{
        maxWidth: '920px',
        padding: '16px',
        marginTop: "24px",
        marginBottom: "64px",
        backgroundColor: "#ffffff"
    }}>

        <Row gutter={8}>

            <Col span={24} lg={12} md={12}>

                {/** Selected Product Variant **/}
                <div style={{border: '1px solid #d1d1d1', marginBottom: '24px', padding: '8px 8px'}}>

                    <div style={{padding: '12px'}}>
                        <Image preview={false} width="100%"
                               style={{border: '1px solid #e1e1e1'}}
                               src={isEmpty(selectedProductVariant?.thumbnailUrl) ? productPlaceholder : selectedProductVariant?.thumbnailUrl}>
                        </Image>
                    </div>

                </div>
            </Col>

            <Col span={24} lg={12} md={12}>

                <div className="product-details-form">
                    <p className={isVisible(hasMultipleVariants())}>
                        {product?.name}
                    </p>

                    <div className={isVisible(hasMultipleVariants())}>
                        <h3>Product Variations</h3>

                        {/*** ------------------ **
                         /* Product Variations
                         *-----------------------**/}
                        <Row>
                            {product?.productVariantList.map((productVariant: ProductVariant) => {
                                return <Col key={productVariant.id} span={12} lg={8} md={12}>
                                    <ProductVariantWidget onSelectionChange={onSelectionChange}
                                                          isSelected={isVariantSelected(productVariant)}
                                                          variant={productVariant}/>
                                </Col>
                            })}
                        </Row>
                    </div>


                    {/** Cart Form **/}
                    <div style={{margin: '', padding: '16px 0px'}}>

                        <Form
                            form={cartForm}
                            name="basic"
                            initialValues={{
                                quantity: 1,
                            }}
                            layout="vertical"
                            requiredMark={false}
                            colon={false}
                            autoComplete="off"
                        >


                            <Row>

                                {/*** Description **/}
                                <Col span={24} lg={14}>
                                    <ul className="products-info-ul" style={{fontSize: '1.2em'}}>
                                        <li className={isVisible(!hasMultipleVariants())}>{product?.name} {selectedProductVariant?.variantName}</li>
                                        <li className="products-price">TZS {(selectedProductVariant?.price ?? 0).toLocaleString()}</li>
                                        <li>{(selectedProductVariant?.availableUnits ?? 0).toLocaleString()} Available</li>
                                    </ul>
                                </Col>

                                {/*** -- Cart Form -- **/}
                                <Col span={24} lg={10}>
                                    <Form.Item
                                        label="Quantity"
                                        name="quantity"
                                        style={{marginTop: 12, textAlign: 'center'}}
                                        rules={[{
                                            required: true,
                                            message: "Quantity can be at least 1",
                                        }]}
                                    >
                                        <Space.Compact style={{width: '100%'}}>
                                            <Button style={{backgroundColor: '#f1f1f1'}}
                                                    size="large"
                                                    icon={<MinusOutlined/>}
                                                    type="default"
                                                    onClick={decrement}/>

                                            <Input name="quantity"
                                                   size="large"
                                                   value={selectedQuantity}
                                                   onChange={onQuantityChange} defaultValue="1"/>

                                            <Button style={{backgroundColor: '#f1f1f1'}}
                                                    size="large"
                                                    type="default"
                                                    icon={<PlusOutlined/>}
                                                    onClick={increment}/>
                                        </Space.Compact>
                                    </Form.Item>
                                </Col>

                                {/*** -- Action Buttons -- */}
                                <Col span={24}>
                                    <Form.Item style={{marginTop: '1em'}}>
                                        <Button size="large" style={{
                                            backgroundColor: "#f1f1f1",
                                            border: '1px solid #463f3a',
                                            color: "#463f3a"
                                        }} loading={isLoading}
                                                type="primary"
                                                icon={<ShoppingCartOutlined/>}
                                                onClick={() => {
                                                    onAddToCart(false)
                                                }}
                                                block>
                                            Add To Cart
                                        </Button>

                                        <Button size="large" style={{backgroundColor: "#463f3a", marginTop: '16px'}}
                                                loading={isLoading} type="primary" htmlType="submit"
                                                onClick={() => {
                                                    onAddToCart(true)
                                                }}
                                                icon={<CheckCircleOutlined/>}
                                                block>
                                            Buy Now
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>


            </Col>

        </Row>

        {/*** ----------------------------
         /* Checkout Confirmation Modal *
         *----------------------------**/}
        <Modal
            style={{maxWidth: '310px'}}
            title={<>
                <h3 style={{margin: '0', padding: '0', color: '#008000'}}>
                    <CheckCircleFilled style={{marginRight: '8px', color: '#008000'}}/>
                    Success
                </h3>
            </>}
            centered
            open={isConfirmationModalVisible}
            onCancel={() => {
                setCartConfirmationModal(false)
            }}
            footer={<></>}
        >

            <div style={{textAlign: "center"}}>
                <p>Product added to cart</p>

                <img style={{marginBottom: '24px'}} width="128px" src={cartIcon} alt=""/>


                <Button block style={{border: '1px solid #463f3a', marginBottom: '12px'}} href="/">
                    Keep Shopping
                    <ProductOutlined/>
                </Button>

                <Button block href="/cart" type="primary" style={{backgroundColor: "#008000"}} danger>
                    Order Now <ArrowRightOutlined/>
                </Button>

            </div>
        </Modal>


    </div>

}

export default ProductDetailsPage

