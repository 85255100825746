import React, {createContext, useContext, useEffect, useState} from 'react';
import {Merchant} from "interfaces/MerchantInterfaces";
import {getRequest} from "services/rest/RestService";
import {getDomainName} from "utils/GoodUrlUtils";
import {notifyError} from "../../services/notification/notifications";

interface MerchantContextProps {
    isLoadingCompany: boolean;
    merchant: Merchant;
    setMerchant: React.Dispatch<React.SetStateAction<Merchant>>;
}

const MerchantContext = createContext<MerchantContextProps | undefined>(undefined);

export const MerchantProvider = ({children}: any) => {

    const [merchant, setMerchant] = useState<Merchant>({name: '', branch: undefined});
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        getCompany();
    }, []);

    const getCompany = () => {
        const merchantDomain = getDomainName();
        const uri = `/api/v1/market/public/merchants/info?domain=${merchantDomain}`;
        console.log(`fetching merchant... ${uri}`)
        setLoading(true)
        getRequest(uri)
            .then((response) => {
                console.log(`merchant-resp: ${JSON.stringify(response.data)}`)
                setMerchant(response.data.respBody)
            })
            .catch((errorObj) => {
                console.log(JSON.stringify(errorObj))
                notifyError("Request could be fulfilled",   "Please try again later")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <MerchantContext.Provider value={{merchant: merchant, isLoadingCompany: loading, setMerchant: setMerchant}}>
            {children}
        </MerchantContext.Provider>
    );
};

export const useMerchantContext = () => {
    const context = useContext(MerchantContext);
    if (!context) {
        throw new Error('merchant must be used within a CounterProvider');
    }
    return context;
};

export default MerchantContext;
