import React, {useEffect} from 'react';
import {
    LoginOutlined,
    LogoutOutlined,
    ShoppingCartOutlined,
    TruckOutlined,
    UserOutlined
} from "@ant-design/icons";
import {useMerchantContext} from "state/contexts/MerchantContext";
import {useCartContext} from "state/contexts/CartContext";
import {useNavigate} from "react-router-dom";
import {Avatar, Badge, Button, Dropdown, Flex, Menu, MenuProps, Popconfirm, Space} from "antd";
import authContext, {useAuthContext} from "../state/contexts/AuthContext";
import {isVisible} from "utils/GoodCSSUtils";
import Search from "antd/es/input/Search";
import {useSearchContext} from "state/contexts/SearchContext";
import 'css/header.css'

const GoodHeader = () => {

    const {merchant} = useMerchantContext()
    const {cartProducts} = useCartContext()
    const {isAuthenticated, customer} = useAuthContext()
    const {setSearchTerm} = useSearchContext();

    useEffect(() => {
    }, [cartProducts]);

    const navigate = useNavigate()

    const toHome = () => {
        navigate("/");
    }

    const toCart = () => {
        navigate("/cart");
    }

    const navigateToOrders = () => {
        navigate("/orders");
    }

    const onLogout = () => {
        localStorage.clear()
        window.location.replace('/')
    }

    const navigateToProfile = () => {
        window.location.replace('/profile')
    }

    const onSearchTermChange = (newSearchTerm: any) => {
        console.log("searching...")
        setSearchTerm(newSearchTerm)
    }

    const popMenuItem = (icon: any, label: string, showMenu: boolean, action?: any) => {
        if (!showMenu) {
            return <></>
        }
        return <div onClick={action} style={{padding: '8px 0px 8px 0px', cursor: 'pointer'}}>
            <Flex gap="middle">
                <p style={{textAlign: 'end', color: '#00509d', margin: '0px'}}>
                    {icon}
                </p>
                <p style={{color: '#00509d', margin: '0px'}}>{label}</p>
            </Flex>
        </div>
    }

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <Button style={{color:'#fefefe'}}
                        className={isVisible(!isAuthenticated)}
                        href="/auth/login"
                        type="primary">
                    <LoginOutlined/> Login
                </Button>
            ),
        },
        {
            key: '2',
            label: (
                popMenuItem(<UserOutlined/>,
                    customer?.firstName ?? 'Profile',
                    isAuthenticated,
                    navigateToProfile)
            )
        },
        {
            key: '3',
            label: (
                popMenuItem(<TruckOutlined style={{fontSize: '20px'}}/>,
                    "My Orders",
                    true,
                    navigateToOrders)
            ),
        },
        {
            key: '4',
            label: (
                popMenuItem(<LogoutOutlined/>,
                    "Logout",
                    isAuthenticated,
                    onLogout)
            ),
        },
    ];


    return <>
        <header>

           <div className="header">

               {/*** Left */}
               <div className="header-left" >
                   <Space align="start">
                       <a href="/">
                           <img className="logo logo-large"
                                src={merchant?.logoUrl ?? ''}
                                alt={merchant?.name ?? ''}
                                style={{height: "36px"}}/>
                       </a>
                       <h2 className="header-company-name">
                           <a style={{textDecoration:'none', color:'inherit'}} href="/">{merchant?.name ?? ''}</a>
                       </h2>
                   </Space>
               </div>

               {/*** Middle ***/}
               <div className="header-middle">
                   <Search
                       className="header-search"
                       size="middle"
                       placeholder="Search"
                       allowClear
                       onSearch={onSearchTermChange}
                   />
               </div>


               {/*** Right  ***/}
               <div className="header-right">

                   <div >
                       <a href="/">
                           <img className="logo logo-small"
                                src={merchant?.logoUrl ?? ''}
                                alt={merchant?.name ?? ''}
                                style={{height: "36px"}}/>
                       </a>
                   </div>

                   <div>
                       {/*** Customer Name ***/}
                       <div style={{display: 'inline', marginRight:'8px'}}>
                           <a href="/profile" style={{textDecoration:'none', color:'inherit'}}>{customer?.firstName} </a>
                       </div>

                       <div style={{display: 'inline'}}>
                           <Dropdown overlayStyle={{border: '1px solid #adadad', borderRadius:'6px' }}
                                     menu={{items}}
                                     placement='bottomRight'
                                     arrow
                                     trigger={['click']}>
                               <Avatar style={{backgroundColor: '#6a6a6a', cursor: 'pointer', marginRight: '16px'}}
                                       icon={<UserOutlined color="#ffffff"/>}></Avatar>
                           </Dropdown>
                       </div>


                       <div style={{display: 'inline'}}>
                           <Badge color="#0077b6" count={cartProducts.length}>
                               <Avatar onClick={toCart} style={{backgroundColor: '#6a6a6a', cursor: 'pointer'}}
                                       icon={<ShoppingCartOutlined color="#ffffff"/>}>
                               </Avatar>
                           </Badge>
                       </div>
                   </div>

               </div>
           </div>

        </header>
    </>;
}

export default GoodHeader

