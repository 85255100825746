import React, {useState, useEffect} from 'react';
import {postRequest} from "services/rest/RestService";
import {notifyHttpError} from "services/notification/notifications";
import {Button, Col, Flex, Row} from "antd";
import {useCartContext} from "state/contexts/CartContext";
import CartProductWidget from "./CartProductWidget";
import {useMerchantContext} from "state/contexts/MerchantContext";
import {CartProduct} from "interfaces/ProductInterfaces";
import {isEmpty} from "utils/helpers";
import {OrderQuotation} from "interfaces/OrderInterfaces";
import {ArrowRightOutlined, ShoppingCartOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {isVisible} from "utils/GoodCSSUtils";

const CartPage = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [orderQuotation, setOrderQuotation] = useState<OrderQuotation | undefined>(undefined);
    const {cartProducts, removeFromCart} = useCartContext()
    const {merchant} = useMerchantContext()
    const navigate = useNavigate()

    //Fetch products
    useEffect(() => {
        fetchQuotation();
    }, [merchant]);

    //Fetch Packages
    const fetchQuotation = () => {
        if (isEmpty(merchant.id)) {
            return;
        }
        setIsLoading(true)
        const payload = {
            "branchId": merchant.branch?.id,
            "businessId": merchant.id,
            "cartProducts": formatCartProducts(),
            "deliveryInformation": {},
            "customer": {},
            "paymentInformation": {}
        }

        console.log(`fetching quotation... ${JSON.stringify(payload)}`)
        postRequest("/api/v1/market/public/checkout/order/quotation", payload)
            .then((response) => {
                setIsLoading(false)
                setOrderQuotation(response.data.respBody.businessOrder)
                console.log(JSON.stringify(response.data.respBody.businessOrder));
            }).catch((errorObj) => {
            setIsLoading(false)
            notifyHttpError('Sorry, request failed. Try again later', errorObj)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const formatCartProducts = () => {
        return cartProducts.map(localCartProduct => {
            return {
                productVariantId: localCartProduct.productVariant?.id ?? '',
                quantity: localCartProduct.quantity
            }
        });
    }

    const onRemoveItem = (cartProduct: CartProduct) => {
        removeFromCart(cartProduct);
    }

    const summaryItem = (label: string, value: number, suffix: string) => {
        return <div style={{borderBottom: '1px dashed #a1a1a1', padding: '0px 0px', margin: '0px 0px 8px 0px'}}>
            <Flex gap="middle">
                <p style={{width: '50%'}}>{label}</p>
                <p style={{width: '50%', textAlign: 'end'}}>
                    {(value).toLocaleString()} {suffix}
                </p>
            </Flex>
        </div>
    }

    const totalItem = (label: string, value: number, suffix: string) => {
        return <div style={{ padding: '0px 0px', margin: '0px 0px 8px 0px'}}>
            <Flex gap="middle">
                <p style={{width: '50%'}}>{label}</p>
                <p style={{width: '50%', textAlign: 'end', fontSize: '18px', fontWeight: 'bold'}}>
                    {(value).toLocaleString()} {suffix}
                </p>
            </Flex>
        </div>
    }

    const navigateToCheckout = () => {
        navigate("/checkout");
    }

    const hasProducts = (): boolean => {
        return cartProducts.length > 0
    }

    return <div className="container" style={{
        maxWidth: '900px',
        paddingLeft: '16px',
        borderRadius: '8px',
        padding: '16px',
        marginTop: "64px",
        marginBottom: "64px",
        backgroundColor: "#ffffff"
    }}>


        <Row gutter={16}>
            <Col span={24} lg={12} md={12}>
                <div style={{border: '1px solid #d1d1d1', padding: '16px 16px'}}>
                    <h3><ShoppingCartOutlined/> Products In Cart </h3>
                    {cartProducts.map((cartProduct) => {
                        return <CartProductWidget
                            key={cartProduct.productVariant.id}
                            onRemove={onRemoveItem}
                            isSelected={true}
                            cartProduct={cartProduct}></CartProductWidget>
                    })}


                    {!hasProducts() && <p>
                        Your cart is empty
                    </p>}

                </div>
            </Col>

            {/* Form */}
            <Col span={24} lg={12} md={12}>
                <div
                    className={`${isVisible(hasProducts())}`}
                    style={{border: '1px solid #d1d1d1', padding: '16px 16px'}}>
                    <h3> Order Summary </h3>

                    {/*Sub Total*/}
                    {summaryItem("Sub Total", orderQuotation?.itemsPricesBeforeDiscount ?? 0, 'TZS')}

                    {/*Tax*/}
                    {summaryItem("Tax", orderQuotation?.vat ?? 0, 'TZS')}

                    {/*Discount*/}
                    {summaryItem("Discount", orderQuotation?.totalDiscountsIssued ?? 0, 'TZS')}

                    {/*Due*/}
                    {totalItem("Total Amount Due", orderQuotation?.amountDue ?? 0, 'TZS')}

                    <Button
                        onClick={navigateToCheckout}
                        size="large"
                        loading={isLoading}
                        block type="primary"
                        style={{marginTop: '24px'}}>
                        Next
                        <ArrowRightOutlined color="#ffffff"/>
                    </Button>
                </div>

            </Col>

        </Row>

    </div>;

}

export default CartPage

