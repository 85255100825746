
export function toBase64Params(phone: string,email: string, callbackUrl?: string){
    const rawParams = JSON.stringify({phone: phone, email: email, redirectionUrl: callbackUrl??''})
    console.log(`rawParams`,rawParams)
    return  window.btoa(rawParams);
}

export function getDomainName(){
    return window.location.hostname;
}

export function getFullHost(){
    return `${window.location.protocol}//${window.location.hostname}`;
}